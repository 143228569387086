@import '~antd/dist/antd.css';

body {
  box-sizing: border-box;

  .ant-layout-header {
    padding: 0;
  }

  .site-page-header {
    border: 1px solid rgb(235, 237, 240);
  }
}

.login-page {
  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 70%;

    > h1, > h2 {
      text-align: center;
    }
    
    > h1 {
      letter-spacing: .75rem;
      font-weight: 500;
    }
    
    > h2 {
      font-weight: 400;
      letter-spacing: .5rem;
    }

    .ant-image-img {
      width: 100%;
    }

    .ant-form {
      width: 100%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
