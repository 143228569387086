.side-panel {
  background-color: #2e2e2e;
  color: #f5f5f5;
  font-size: 0.8rem;
  height: 97vh;

  .side-panel-header {
    padding: 1rem;
    // display: flex;

    div {
      font-size: 0.65rem;
      .username {
        text-transform: uppercase;
        margin-bottom: 0;
      }

      margin-top: 0.5rem;
    }
  }

  ul {
    text-transform: uppercase;
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;
      color: unset;
    }

    li {
      padding: 1rem;
      cursor: pointer;

      &.active {
        color: #f5d000;

        &:hover {
          cursor: not-allowed;
        }
      }

      &:not(.active):hover {
        color: #2e2e2e;
        background-color: #f5d000;
      }

      &.btn-sign-out:hover {
        background-color: #bf2e06;
        color: #f5f5f5;
      }
    }
  }
}
